import {
  crmFormEmailValidator,
  crmFormRequiredValidator,
} from 'common-module/form';

export const requiredValidator = crmFormRequiredValidator({
  error: 'generic.requiredField',
});

export const emailValidator = crmFormEmailValidator({
  error: 'generic.validEmailMessage',
});
